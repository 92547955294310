import { defineStore } from 'pinia';
import type { IOpenItem } from '~/repository/modules/cases/cases.types';
import type { TPossibleNull } from '~/types/Shared.types';

export const useFinalDropsStore = defineStore('cases/drops', () => {
  const finalDrop = ref<IOpenItem[]>([]);
  const finalTastyCoins = ref(0);
  const finalPrizeSegment = ref<TPossibleNull<number>>(null);

  function setFinalDrop(drop: IOpenItem[] = []) {
    finalDrop.value = drop;
  }

  function setPrizeSegment(prizeSegment: number | null) {
    // prizeSegment с бэка отсчитывается с 1, поэтому для соответствия первому элементу массива вычитаем 1
    finalPrizeSegment.value = prizeSegment === null ? prizeSegment : prizeSegment - 1;
  }

  function setTastyCoins(coins = 0) {
    finalTastyCoins.value = coins;
  }

  return {
    finalDrop,
    finalPrizeSegment,
    finalTastyCoins,
    setFinalDrop,
    setPrizeSegment,
    setTastyCoins,
  };
});
