import { defineStore } from 'pinia';
import { ErrorCodes } from '~/api/global/errors/codes/codes';
import { useAlertStore } from '~/store/alert/alert.store';
import type { IDropType } from '~/features/cases/components/MultiDrop/MultiDrop.types';
import type { IOpenItem } from '~/repository/modules/cases/cases.types';
import { Currency } from '~/constants/currency.constants';

export const useDropWinnersStore = defineStore('cases/dropWinner', () => {
  const { $api } = useNuxtApp();
  const alertStore = useAlertStore();
  const { t } = useI18n();

  // выигранные предметы
  const dropsWinner = ref<IOpenItem[]>([]);
  // id юзера, который отправил ссылку на дроп
  const userLinkId = ref<number>();
  // Необходимо для оценки окупного предмета на странице дропа
  const casePrice = ref<number>(0);
  const dropWinnerTastyCoins = ref<number | null>();
  const isPending = ref<boolean>(false);
  const getDropsWinnerTastyCoins = computed(() => dropWinnerTastyCoins.value || null);

  // будем сбрасывать данные о победных кейсах при начале следующего прокрута
  const resetDropsWinner = () => {
    dropsWinner.value = [];
    dropWinnerTastyCoins.value = null;
  };

  /**  Получить выигранные предметы на страницы дропа
   * @param {string} caseName - название кейса
   * @param {string} gameId - уникальный идентификатор игры
   * **/
  const getDrops = async (caseName: string, gameId: string): Promise<void> => {
    resetDropsWinner();
    await GlobalUtils.Api.handleRequest(
      async () => {
        const { data } = await $api.cases.getDrops(caseName, gameId);
        if (data.value) {
          casePrice.value = data.value.data.casePrice;
          userLinkId.value = data.value.data.userId;
          dropsWinner.value = data.value.data.items;
        }
      },
      (e) => {
        alertStore.showError({
          message: e.msg,
          title: ErrorCodes.UNPREDICTED_EXCEPTION,
        });
      },
      isPending,
    );
  };

  const publicMultiDrop = () => {};

  const initShareButton = ({ name, priceOnProject, img }: IDropType, caseName: string, element: HTMLElement) => {
    // @ts-expect-error TODO remove or refactor
    if (import.meta.server || !window?.Ya) return;
    const { defaultCurrency } = useProjectSettings();

    const config = useRuntimeConfig();
    const link = config.public.baseUrl + `/open/${caseName}`;
    const title = t('lostLocales.dropWinnerTitle', {
      name,
      priceOnProject,
      link,
      currency: Currency[defaultCurrency],
    });
    // @ts-expect-error TODO remove or refactor
    window.Ya.share2(element, {
      content: {
        image: img,
        title,
        url: link,
      },
      hooks: {
        onready: () => {
          /*
            Yandex share не позволяет задать кастомную иконку соцсети, поэтому пришлось манипулировать стилями
          */
          const list = element.querySelector('ul');
          const listItem = list?.querySelector('li');
          if (!list || !listItem) return;
          list.style.width = listItem.style.width = '100%';
          listItem.querySelectorAll('span').forEach((element: HTMLElement) => (element!.style.background = 'none'));
        },
      },
      theme: {
        services: 'vkontakte',
      },
    });
  };
  return {
    casePrice,
    dropWinnerTastyCoins,
    dropsWinner,
    getDrops,
    getDropsWinnerTastyCoins,
    initShareButton,
    publicMultiDrop,
    resetDropsWinner,
    userLinkId,
  };
});
