import { defineStore } from 'pinia';
import type { IDropItem, IPriceData } from '~/repository/modules/cases/cases.types';
import type { TCaseDropItem } from '~/features/cases/types/case.types';
import { useCaseStore } from '~/features/cases/store/useCases.store';
import type { TDropItemType } from '~/types/cases/dropItem';
import type { IInventoryPackContent } from '~/repository/modules/inventory/inventory.types';

const LIMIT_SHOW_DROP_ITEM = 64;

const DIFF_TIMING = 50; // Задержка в миллисекундах перед началом спина рулетки при множественных релетках

/**
 * Возвращает значение в миллисекундах,
 * добавляемое к длительности анимации конкретной рулетки,
 * чтобы рулетки останавливались последовательно друг за другом, а не одновременно
 @param {number} index - индекс рулетки
 @returns {number} - значение в миллисекундах
 **/
const getTimeGap = (index: number): number => {
  switch (index) {
    default:
      return 0;
  }
};

export const useCarouselStore = defineStore('cases/carousel', () => {
  const caseStore = useCaseStore();

  const carouselsLists = ref<ISimplifiedItem[][]>([]);
  const carouselStates = ref<ICarouselState[]>([]);
  const resolvedCarouselsCount = ref(0);
  const isCarouselsCloned = ref(false);

  const showWonPopUp = ref<boolean>(false);

  const isSpinInProcess = computed<boolean>(() => {
    return carouselStates.value.filter((el) => el.isSpinning).length !== 0;
  });

  // Индекс элемента с правого края массива будет являться победным
  const idxFromTheRightBorderArrayToWinnerElement = ref<number>(6);

  function startCarousels(
    carouselsFns: Array<{
      start: (timeGap?: number) => void;
      stop: () => void;
    }> = [],
  ) {
    if (!carouselsFns || !carouselsFns.length) return;

    if (carouselsFns.length === 1) {
      const state = carouselStates.value[0];
      state && (state.notYetStarted = false);

      nextTick(() => {
        carouselsFns[0].start();
      }).then(() => {
        state && (state.isSpinning = true);
      });
      return;
    }

    carouselsFns.forEach((handler, index) => {
      const state = carouselStates.value[index];
      state && (state.notYetStarted = false);

      const promise = new Promise((resolve) => {
        setTimeout(() => {
          handler.start(getTimeGap(index));
          resolve(true);
        }, index * DIFF_TIMING);
      });

      promise.then(() => {
        state && (state.isSpinning = true);
      });
    });
  }

  const setCheapestItemAsWonDrop = (dropList: IDropItem[]) => {
    const cheapest = dropList.sort((a, b) => Number(a.priceData.price) - Number(b.priceData.price))[0];
    appendWinnerItem(0, cheapest);
  };

  /** Добавить выигранный предмет в нужную рулетку - в нужное место
   * @param {Number} idxCarousel - Индекс карусели
   * @param {IDropItem} item - Выигранный предмет
   * @return {void}
   * **/
  function appendWinnerItem(idxCarousel: number, item: IDropItem): void {
    const currentCarousel = carouselsLists.value[idxCarousel];
    // Точная позиция для замены предмета
    const idxAppendNewItem = currentCarousel.length - idxFromTheRightBorderArrayToWinnerElement.value;
    currentCarousel[idxAppendNewItem] = transformToSimplifyElement(item);
  }

  /*
    Если в кейсе элементов меньше, чем LIMIT_SHOW_DROP_ITEM, то ф-я дополняет массив
    Это нужно, чтобы анимация рулетки всегда отрабатывала нормально
  */
  function getNormalizedItemsList(itemsList: TCaseDropItem[]) {
    if (!itemsList || !itemsList.length) return [];

    itemsList = itemsList.map((item) => ({ ...item, id: GlobalUtils.Maths.randomIntFromInterval(0, 1000000) }));
    let processed = [...itemsList, ...itemsList];

    if (processed.length < LIMIT_SHOW_DROP_ITEM) {
      for (let i = 0; i < LIMIT_SHOW_DROP_ITEM; i += itemsList.length) {
        processed = [...processed, ...itemsList];
        if (processed.length >= LIMIT_SHOW_DROP_ITEM) break;
      }
    }
    return processed.map(transformToSimplifyElement).slice(0, LIMIT_SHOW_DROP_ITEM);
  }

  function setCarousels(itemsList: TCaseDropItem[] = []) {
    carouselsLists.value = [];
    const normalizedItemsList = getNormalizedItemsList(itemsList);
    carouselsLists.value.push(GlobalUtils.Objects.shuffleArray(normalizedItemsList));
  }

  function setWonPopUpStatus(value: boolean) {
    showWonPopUp.value = value;
  }

  function endCarouselSpin(idx: number) {
    carouselStates.value[idx].isSpinning = false;
    carouselStates.value[idx].isFinished = true;
  }

  /** Преобразование DropItem в упрощённый формат карточек ISimplifiedItem
   * @param {IDropItem} obj - Исходный объект предмета
   * @return {ISimplifiedItem} - Упрощённый объект
   * **/
  function transformToSimplifyElement({
    imageData,
    name,
    priceData,
    qualityEnum,
    type,
    packItems,
  }: IDropItem): ISimplifiedItem {
    return {
      id: GlobalUtils.Maths.randomIntFromInterval(0, 100000),
      image: imageData.image,
      key: GlobalUtils.Maths.createUniqueId(10),
      name,
      priceData,
      quality: qualityEnum.name,
      type,
      packItems,
    };
  }

  function cloneCarousels(count: number) {
    const temp = carouselsLists.value[0];

    carouselsLists.value = new Array(count).fill('').map((_, idx) => {
      if (idx === 0) return temp;
      return GlobalUtils.Objects.shuffleArray(temp);
    });
  }

  watch(
    () => caseStore.caseMultiplier,
    (multiplier: number) => {
      carouselStates.value = createStates(multiplier);
    },
  );

  return {
    appendWinnerItem,
    carouselStates,
    carouselsLists,
    cloneCarousels,
    endCarouselSpin,
    idxFromTheRightBorderArrayToWinnerElement,
    isSpinInProcess,
    resolvedCarouselsCount,
    setCarousels,
    setWonPopUpStatus,
    showWonPopUp,
    startCarousels,
    setCheapestItemAsWonDrop,
    isCarouselsCloned,
  };
});

function createStates(count = 1) {
  const arr: ICarouselState[] = [];
  for (let i = 0; i < count; i++) {
    arr.push({
      isFinished: false,
      isPreFinished: false,
      isSpinning: false,
      notYetStarted: true,
    });
  }
  return arr;
}

// TODO техдолг
export interface ISimplifiedItem {
  id: string | number;
  image: string;
  key?: string;
  name: string;
  priceData: Omit<IPriceData, 'oldPrice'>;
  quality: string;
  type?: TDropItemType;
  packItems?: IInventoryPackContent[];
}

export interface ICarouselState {
  isFinished: boolean;
  isPreFinished: boolean;
  isSpinning: boolean;
  notYetStarted: boolean;
}
